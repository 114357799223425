.loginlogo7 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5vh 0 6vh 0;
}

.logo {
  width: 35%;
}

.loginContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.loginTitle {
  font-size: 20px;
}

.description{
  width: 90vw;
  margin: 5vh 0 5vh 0;
}

.containerLoginFormBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.loginFormBtn {
  font-size: 16px;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94vw;
  height: 8vh;
  background: #283a5b;
}

.loginFormBtn:hover {
  cursor: pointer;
}

.containerRegisterFormBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.registerFormBtn {
  font-size: 16px;
  color: #283a5b;
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 5vh 0 5vh 0;
}

.obs{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 95vw;
}
 
.obs p{
  width: 70vw;
}

.error {
  color: red;
  font: 400 14px "Roboto", sans-serif;
}
