.faqContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqItem {
  width: 95vw;
}

.faqQuestion {
  max-width: 90vw;
  margin: 3vh 0 3vh 0;
}

.faqAnswer {
  max-width: 95vw;
}

.separadorHorizontal2 {
  display: flex;
  border: 1px solid #36bae2;
  width: 100vw;
  margin: 3vh 0 3vh 0;
}

.faqQuestionIcon {
  display: none;
}

/* Estilos para Telas de Tablet */
@media (min-width: 768px) {
}

/* Estilos para Telas de Notebook/Desktop */
@media (min-width: 992px) {
  .faqContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .separadorHorizontal2 {
    width: 45vw;
  }

  .faqQuestion {
    max-width: 40vw;
    margin: 3vh 0 3vh 0;
    display: flex;
    justify-content: flex-start;
  }

  .question {
    width: 55vw;
    max-width: 60vw;
  }

  .faqAnswer {
    width: 38vw;
    max-width: 40vw;
  }

  .faqQuestionIcon {
    display: flex;
    justify-content: end;
    width: 5vw;
  }

  .faqQuestionIcon svg {
    transition: transform 0.3s ease-in-out;
  }

  .faqQuestionIcon.open svg {
    transform: rotate(180deg);
  }
}
