.contractTelecomButton {
  width: 40vw;
  height: 6vh;
}

.contractTelecomButton button {
  width: 40vw;
  height: 6vh;
  background-color: #36bae2;
  border: none;
  border-radius: 5px;
  color: #283a5b;
  font-size: 16px;
  font-weight: 500;

}

.contractTelecomButtonCp {
  width: 40vw;
  height: 8vh;
  background-color: #36bae2;
  border: none;
  border-radius: 5px;
  color: #283a5b;
  font-size: 16px;
  font-weight: 500;
}

/* Estilos para Telas de Tablet */
@media (min-width: 768px) {
}

/* Estilos para Telas de Notebook/Desktop */
@media (min-width: 992px) {
  .contractTelecomButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17vw;
    height: 8vh;
    border-radius: 5px;
  }

  .contractTelecomButton  button{
    width: 15vw;
    height: 6vh;
    background-color: #36bae2;
    color: #283a5b;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #36bae2;
    border-radius: 5px;
    box-shadow: 0 0 6px 2px #36bae2;
  }

  .contractTelecomButtonCp {
    width: 10vw;
    height: 6vh;
    background-color: #36bae2;
    border: none;
    border-radius: 5px;
    color: #283a5b;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}
