.loginlogo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5vh 0 5vh 0;
}

.logo {
    width: 35%;
}

.loginContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 100vh;
}

.loginTitle {
    font-size: 20px;
}

.wrapInput {
    width: 94vw;
    height: 12vh;
    position: relative;
    border: 1px solid #989898;
    border-radius: 8px;
    margin: 0;
}

.wrapInput2 {
    width: 94vw;
    height: 12vh;
    position: relative;
    border: 1px solid #989898;
    border-radius: 8px;
    margin: 0;
}

.input {
    font-size: 15px;
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0 5vw;
}

.input2 {
    font-size: 15px;
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0 5vw;
}

.inputTitle{
    margin: 4vh 0 2vh 0;
    font-size: 15px;
}

.inputTitl span{
    font-size: 13px;
}

.input:focus{
    outline: none;
}

.containerLoginFormBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.advise{
    margin: 2vh 0 5vh 0;
    font-size: 13px;
}

.loginFormBtn {
    font-size: 16px;
    border: none;
    font-weight: bold;
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94vw;
    height: 8vh;
    background: #283A5B;

}

.loginFormBtn:hover {
    cursor: pointer;
}

.containerRegisterFormBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.registerFormBtn{
    font-size: 16px;
    color: #283A5B;
    text-decoration: none;
    border: none;
    background-color: transparent;
    margin: 5vh 0 5vh 0;
}

.error {
    color: red;
    font: 400 14px 'Roboto', sans-serif;
}