.loginlogo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 7vh 0 7vh 0;
}

.logo {
  width: 35%;
}

.loginContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 95vw;
  height: 100vh;
}

.loginTitle {
  font-size: 20px;
}

.loginDescription {
  margin: 5vh 0 5vh 0;
}

.wrapTokenInput {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
  width: 94vw;
  height: 11vh;
  border: none;
}

.tokenInput {
  border: 1px solid #989898;
  border-radius: 8px;
  font-size: 30px;
  padding: 1px 1px 1px 1px;
  color: #283a5b;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 13vw;
  height: 10vh;
  background-color: transparent;
}

.tokenInput:focus {
  outline: none;
}

.containerValidateFormBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.validateFormBtn {
  font-size: 16px;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94vw;
  height: 8vh;
  background: #283a5b;
}

.validateFormBtn:hover {
  cursor: pointer;
}

.containerResendFormBtn {
  
}

.resendFormBtn {
  font-size: 16px;
  color: #283a5b;
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 3vh 0 5vh 0;
}

.error {
  color: red;
  font: 400 14px "Roboto", sans-serif;
}
