main {
  max-width: 100vw;
  text-wrap: wrap;
}

header {
  display: flex;
  flex-direction: column;
  height: 20vh;
  max-width: 100vw;
  margin: 0 0 5vh 0;
}

header a {
  font-size: 12px;
  color: black;
  text-decoration: none;
}

.separadorVertical {
  display: none;
}

.line01 {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLogo {
  width: 24vw;
  margin: 2vh 0 5vh 8vw;
  display: flex;
  justify-content: center;
}

.mainButtonsA {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.homeButton {
  display: none;
}

.loginButton01 {
  margin: 0 8vw 0 0vw;
  display: flex;
  border: none;
  text-decoration: none;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
}

.loginButton02 {
  display: none;
}

body {
  max-width: 100vw;
}

.banner01 {
  background-color: #283a5b;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8vh 4vw 8vh 4vw;
}

#planAds {
  display: flex;
  margin: 2vh 2vw 2vh 2vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 4px solid #36bae2;
  border-radius: 25px;
  padding: 3vh 3vw 3vh 3vw;
  box-shadow: 0 0 6px 2px #36bae2;
}

.separadorHorizontal {
  background-color: #36bae2;
  border: 1px solid #36bae2;
  width: 95%;
  border-radius: 50px;
  margin: 1vh 0 1vh 0;
  box-shadow: 0 0 6px 0px #36bae2;
}

#planAds p {
  font-size: 10px;
}

#planAds span {
  color: red;
  text-decoration: line-through;
}

#planAds #adsGigas {
  font-size: 15px;
}

.line02 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.line02 h1 {
  max-width: 50vw;
  font-size: 20px;
}

#line02 {
  color: #36bae2;
}

.line03 {
  margin: 5vh 0 5vh 0;
  max-width: 80vw;
}

.line03 p {
  font-size: 14px;
}

.line04 {
  display: none;
}

.alfaInternet {
  margin: 5vh 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alfaInternet h2 {
  margin: 0 0 2vh 0;
  max-width: 90vw;
  font-size: 20px;
  font-weight: 500;
}

.alfaInternet p {
  max-width: 95vw;
  font-size: 15px;
}

.duvidas01 {
  display: none;
}

.banner02 {
  display: none;
}

.alfaTelecom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alfaTelecom h2 {
  margin: 0 0 2vh 0;
  max-width: 90vw;
  font-size: 20px;
  font-weight: 500;
}

.alfaTelecom p {
  max-width: 95vw;
  font-size: 15px;
}

.plansTelecom {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: space-evenly;
  margin: 5vh 0 0 0;
}

.plansInternet {
  margin: 5vh 0 0 0;
}

.duvidas02 {
  display: none;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutUs h2 {
  margin: 0 0 2vh 0;
  max-width: 80vw;
  font-size: 20px;
  font-weight: 500;
}

.aboutUs p {
  max-width: 95vw;
  font-size: 15px;
  margin: 0 0 2vh 0;
}

.aboutUs img {
  display: none;
}

.faq {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  margin: 5vh 0 5vh 0;
}

.faqTitle {
  max-width: 95vw;
}

.faq h2 {
  margin: 0 0 2vh 0;
  width: 95vw;
  font-size: 20px;
}

.faq p {
  max-width: 95vw;
  font-size: 15px;
}

.faq a {
  text-decoration: none;
  color: #36bae2;
}

footer {
  max-width: 100vw;
  background-color: #283a5b;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 3vh 5vw 1vh 5vw;
  font-weight: 200;
}

.footer .separadorVertical {
  display: none;
}

footer h1 {
  margin: 0 0 2vh 0;
  max-width: 90vw;
  font-size: 24px;
  font-weight: 300;
  color: #36bae2;
}

footer p {
  margin: 0 0 2vh 0;
  max-width: 60vw;
  font-size: 15px;
}

footer a {
  text-decoration: none;
  color: #ffffff;
}

.email {
  margin: 2vh 0 2vh 0;
}

footer span {
  color: #36bae2;
  font-weight: 300;
}

footer img {
  width: 10vw;
  margin: 0 0 1vh 0;
}

.termos {
  margin: 0;
  padding: 0;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.termos p {
  max-width: 90vw;
  font-size: 12px;
  margin: 2vh 0 0 0;
}

.termos a {
  max-width: 100vw;
  font-size: 15px;
  margin: 2vh 0 2vh 0;
}

.copy a {
  font-size: 13px;
}

#termos {
  margin: 2vh 0 2vh 0;
}

#contactsImg {
  display: none;
}

.contatosContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 60vh;
}

.contatosTitle {
  font-size: 30px;
  font-weight: 500;
}

.contatosDescription {
  max-width: 95vw;
  font-size: 15px;
  margin: 5vh 0 5vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contatos {
  display: flex;
  flex-direction: column;
}

.contatos02 {
  display: flex;
}

.telefones {
  font-size: 15px;
  width: 40vw;
}

.emails {
  font-size: 15px;
  width: 40vw;
}

.redes {
  font-size: 15px;
  width: 40vw;
}

.telefones p {
  margin: 1vh 0 2vh 0;
}

.emails span p {
  margin: 1vh 0 1vh 0;
}

.redes p {
  margin: 2vh 0 1vh 0;
}

.emails a {
  margin: 1vh 0 2vh 0;
  text-decoration: none;
  color: black;
}

#endereco {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#fbLogo {
  width: 8vw;
}

.emBreve {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 72vh;
}

.emBreveTitle {
  font-size: 30px;
}

.emBreveDescription {
  max-width: 95vw;
  font-size: 15px;
  text-align: center;
}

.emBreveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 8vh;
  border-radius: 5px;
}

.emBreveButton button {
  width: 78vw;
  height: 6vh;
  background-color: #36bae2;
  color: #283a5b;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #36bae2;
  border-radius: 5px;
}

/* Estilos para Telas de Tablet */
@media (min-width: 768px) {
}

/* Estilos para Telas de Notebook/Desktop */
@media (min-width: 992px) {
  header a {
    font-size: 15px;
    color: black;
    text-decoration: none;
  }

  .separadorVertical {
    display: flex;
    border: 1px solid #36bae2;
    height: 55vh;
    margin: 0 2vw 0 2vw;
  }

  .line01 {
    max-width: 15vw;
    display: flex;
  }

  header {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    overflow-y: hidden;
    margin: 0 0 2vh 0;
    padding: 0 0 3vh 0;
  }

  .headerLogo {
    width: 17vw;
    margin: 5vh 0 2vh 0vw;
    display: flex;
    justify-content: center;
  }

  .mainButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .mainButtonsA {
    margin: 0 3vw 0 0vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .mainButtonsA a {
    margin: 0 3vw 0 3vw;
  }

  .mainButtonsB {
    margin: 0 3vw 0 0vw;
  }

  .homeButton {
    display: block;
  }

  .loginButton01 {
    display: none;
  }

  .loginButton02 {
    margin: 0 3vw 0 0vw;
    display: flex;
    border: none;
    text-decoration: none;
    background-color: transparent;
    font-size: 15px;
    cursor: pointer;
  }

  .banner01 {
    background-color: #283a5b;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8vh 4vw 8vh 4vw;
  }

  #planAds {
    max-width: 18vw;
    display: flex;
    margin: 2vh 2vw 2vh 2vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 4px solid #36bae2;
    border-radius: 25px;
    padding: 3vh 1vw 3vh 1vw;
    box-shadow: 0 0 6px 2px #36bae2;
  }

  .separadorHorizontal {
    background-color: #36bae2;
    border: 1px solid #36bae2;
    width: 90%;
    border-radius: 50px;
    margin: 1vh 0 1vh 0;
    box-shadow: 0 0 6px 0px #36bae2;
  }

  #planAds p {
    font-size: 20px;
  }

  #planAds .price {
    font-size: 36px;
  }

  #planAds span {
    color: red;
    text-decoration: line-through;
  }

  #planAds #adsGigas {
    font-size: 48px;
  }

  .line02 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .line02 h1 {
    max-width: 43vw;
    font-size: 64px;
  }

  .line05 p {
    width: 37vw;
    font-size: 24px;
  }

  .line05 {
    margin: 0vh 0vw 3vh 0vw;
  }

  #line02 {
    color: #36bae2;
  }

  .line04 {
    display: block;
    margin: 5vh 0vw 5vh 2vw;
  }

  .line06 {
    display: none;
  }

  .internetDescription {
    display: flex;
    width: 95vw;
  }

  .internetDescription h2 {
    max-width: 70vw;
    font-size: 40px;
  }

  .internetDescription p {
    max-width: 70vw;
    font-size: 20px;
  }

  .internetDescription .separadorVertical {
    height: 25vh;
  }

  .plansInternet {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 0vw 0 0;
    max-width: 85vw;
  }

  .telecomDescription {
    display: flex;
    width: 95vw;
    margin: 3vh 0 0 0;
  }

  .telecomDescription h2 {
    max-width: 70vw;
    font-size: 40px;
  }

  .telecomDescription p {
    max-width: 70vw;
    font-size: 20px;
  }

  .telecomDescription .separadorVertical {
    height: 23vh;
  }

  .plansTelecom {
    display: flex;
    flex-wrap: wrap;
    width: 85vw;
    justify-content: space-evenly;
  }

  .duvidas01 {
    display: block;
    margin: 0 0 2vh 0;
  }

  .duvidas02 {
    display: block;
    margin: 0 0 2vh 0;
  }

  .duvidas01 a {
    text-decoration: none;
    color: #36bae2;
  }

  .duvidas02 a {
    text-decoration: none;
    color: #36bae2;
  }

  .banner02 {
    background-color: #283a5b;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3vh 2vw 3vh 2vw;
    margin: 2vh 0 2vh 0;
    width: 100vw;
    height: 30vh;
  }

  #planAds2 {
    max-width: 20vw;
    display: flex;
    margin: 1vh 1vw 1vh 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 4px solid #36bae2;
    border-radius: 25px;
    padding: 2vh 1vw 2vh 1vw;
    box-shadow: 0 0 6px 2px #36bae2;
  }

  #planAds2 p {
    font-size: 20px;
  }

  .bannerDescription02 h1 {
    margin: 0 0 1vh 0;
    font-weight: 500;
  }

  .bannerDescription02 p {
    margin: 0 0 3vh 0;
    font-weight: 300;
    max-width: 30vw;
  }

  #bannerDescription02 {
    display: flex;
    flex-direction: column;
    max-width: 35vw;
    margin: 0 5vw 2vh 0;
  }

  #planAds2 .price2 {
    font-size: 36px;
  }

  #planAds2 span {
    color: red;
    text-decoration: line-through;
  }

  #planAds2 #adsGigas2 {
    font-size: 48px;
  }

  .aboutUs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 5vh 0 0 0;
  }

  .aboutUs h2 {
    margin: 0 0 2vh 0;
    max-width: 45vw;
    font-size: 40px;
  }

  .aboutUs p {
    max-width: 45vw;
    font-size: 20px;
    margin: 0 0 2vh 0;
  }

  .aboutUs img {
    display: block;
    width: 35vw;
  }

  .faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vh 0 5vh 0;
  }

  #faq {
    margin: 0 0 0 7vw;
  }

  .faq h2 {
    margin: 0 0 2vh 0;
    width: 88vw;
    font-size: 40px;
  }

  .faq p {
    max-width: 88vw;
    font-size: 15px;
  }

  .faq a {
    text-decoration: none;
    color: #36bae2;
  }

  footer {
    max-width: 100vw;
    background-color: #283a5b;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 3vh 5vw 1vh 5vw;
    font-weight: 200;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .footer .endereco {
    width: 40vw;
  }

  .footer .contatos {
    width: 40vw;
  }
  .footer .separadorVertical {
    display: block;
    max-height: 20vh;
  }

  .footer .endereco h1 {
    margin: 0 0 2vh 0;
    max-width: 45vw;
    font-size: 62px;
    font-weight: 300;
    color: #36bae2;
  }

  .footer .endereco p {
    margin: 0 0 2vh 0;
    max-width: 45vw;
    font-size: 20px;
  }

  footer a {
    text-decoration: none;
    color: #ffffff;
  }

  .email {
    margin: 2vh 0 2vh 0;
  }

  footer span {
    color: #36bae2;
    font-weight: 300;
  }

  .footer .contatos img {
    max-width: 2vw;
    margin: 0 0 0 0;
  }

  .termos {
    margin: 0;
    padding: 0;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .termos p {
    max-width: 90vw;
    font-size: 12px;
  }

  .termos a {
    max-width: 100vw;
    font-size: 15px;
    margin: 2vh 0 0 0;
  }

  #termos {
    margin: 2vh 0 2vh 0;
  }

  .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-height: 6vh;
    text-align: center;
    margin: 0;
    font-size: 12px;
  }

  #maincode a {
    font-size: 12px;
  }

  .socialMedia a {
    max-width: 1vw;
    margin: 0;
  }

  .phones p {
    margin: 0vh 0 2.7vh 0;
  }

  .emBreve {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 72vh;
  }

  .emBreveTitle {
    font-size: 46px;
  }

  .emBreveDescription {
    max-width: 60vw;
    font-size: 20px;
    text-align: center;
  }

  .emBreveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17vw;
    height: 8vh;
    border-radius: 5px;
  }

  .emBreveButton button {
    width: 15vw;
    height: 6vh;
    background-color: #36bae2;
    color: #283a5b;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #36bae2;
    border-radius: 5px;
  }

  #fbLogo {
    width: 2vw;
  }

  #contactsImg {
    width: 35vw;
  }

  .contacts {
    display: flex;
    flex-direction: row;
  }

  .phones {
    width: 10vw;
  }

  .contatosContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 72vh;
  }

  .contatosTitle {
    font-size: 48px;
    font-weight: 500;
  }

  .contatosDescription {
    max-width: 50vw;
    font-size: 20px;
    margin: 5vh 0 5vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 17vh;
  }

  .contatos {
    display: flex;
    flex-direction: column;
  }

  .contatos02 {
    display: flex;
  }

  .telefones {
    font-size: 20px;
    width: 15vw;
  }

  .emails {
    font-size: 20px;
    width: 20vw;
  }

  .redes {
    font-size: 20px;
    width: 20vw;
  }

  .telefones p {
    margin: 1vh 0 2vh 0;
  }

  .emails p {
    margin: 1vh 0 1vh 0;
  }

  .redes p {
    margin: 1vh 0 2vh 0;
  }

  .emails a {
    margin: 0 0 2vh 0;
    text-decoration: none;
    color: black;
  }

  #endereco {
    text-align: center;
  }
}
