.contractInternetButton {
  width: 35vw;
  height: 8vh;
  background-color: #36bae2;
  border: none;
  border-radius: 5px;
  color: #002f85;
  font-size: 16px;
  font-weight: 500;
}

.contractInternetButtonCp {
  width: 35vw;
  height: 8vh;
  background-color: #36bae2;
  border: none;
  border-radius: 5px;
  color: #002f85;
  font-size: 16px;
  font-weight: 500;
}

/* Estilos para Telas de Tablet */
@media (min-width: 768px) {
}

/* Estilos para Telas de Notebook/Desktop */
@media (min-width: 992px) {
  .contractInternetButton {
    width: 10vw;
    height: 6vh;
    background-color: #36bae2;
    border: none;
    border-radius: 5px;
    color: #283a5b;
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
  }

  .contractInternetButtonCp {
    width: 10vw;
    height: 6vh;
    background-color: #36bae2;
    border: none;
    border-radius: 5px;
    color: #283a5b;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}
