.container{
    width: 75vw;
    height: 55vh;
    margin: 6vh 0 6vh 0 ;
    padding: 5vh 5vw 5vh 5vw;
    border: solid 1px #283A5B;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.planName p{
    font-size : 16px;
    font-weight: medium;
    margin: 0 0 3vh 0;
}

#planGigas{
    font-size: 32px;
    font-weight: 500;
}

.planBenefits p{
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 15px;
    margin: 2vh 0 3vh 0;
}

.planPrice p{
    font-weight: 500;
    font-size: 16px;
    margin: 3vh 0 3vh 0;
}

.contractButton{
    margin: 3vh 0 0 0;
}


/* Estilos para Telas de Tablet */
@media (min-width: 768px) {
}

/* Estilos para Telas de Notebook/Desktop */
@media (min-width: 992px) {
  .container {
    width: 18vw;
    margin: 4vh 0 4vh 0 ;
    padding: 3vh 2vw 3vh 2vw;
  }
}